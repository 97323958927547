import {
  Box,
  Center,
  Container,
  Image,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";

export const StepwithLine = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  return (
    <Container py={{ base: "16", md: "24" }}>
      <Center w={"full"} h={{ base: "auto", md: "full" }}>
        <Stack
          w={"full"}
          justifyContent={"center"}
          alignItems={"center"}
          spacing={{ base: "8", md: "12" }}
        >
          <Text
            fontSize={{ base: "2xl", md: "4xl" }}
            textAlign={"center"}
            fontWeight={"700"}
            fontFamily="'Cafe24 Ssurround', sans-serif"
            color="#345459"
          >
            당신의 이야기를 들려주세요!<br />
            나머지는 다 저희가 할게요
            <Box h={6} w={"full"} mt={-8} rounded="full" bg="#BFF6F6"></Box>
          </Text>
          <Box p={{ base: "4", md: "12" }}>
            {!isMobile ? (
              <Image src={require("../Assets/Image/stepImage.png")} />
            ) : (
              <Image src={require("../Assets/Image/mobilestepImage.png")} />
            )}
          </Box>
        </Stack>
      </Center>
    </Container>
  );
};
