import { Box } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

// 레이아웃 관련 컴포넌트 임포트
import { LayoutWithFullContentHeight } from "../Marketing/Layouts/LayoutWithFullContentHeight/App";
import { Navbar } from "../Marketing/Layouts/LayoutWithFullContentHeight/Navbar";
import { Footer } from "../Marketing/Layouts/LayoutWithFullContentHeight/Footer";

// 히어로 관련 컴포넌트 임포트
import { WithImageBackground } from "../Marketing/Heroes/Hero3";
import { HeroWithImage } from "../Marketing/Heroes/Hero5";
import { HeroWithCropedImage } from "../Marketing/Heroes/Hero1";

// 그리드 및 기타 섹션 임포트
import { LogoGridWithTitle } from "../Marketing/LogoGrid/LogoGridWithTitle/App";
import { StatsWithDivider } from "../Marketing/Stats/StatsWithDivider/App";
import { Features } from "../Marketing/Features/App";
import { CTA1 } from "../Marketing/CallToActions/CTA1";
import { BlogWithThreeColumns } from "../Marketing/Blog/BlogWithThreeColumns/App";

// 추가적인 섹션 임포트
import { StepwithLine } from "./StepwithLine";
import { BeforeAndAfter } from "./BeforeAndAfter";

// Firebase 인증 관련 임포트
import { auth } from "../Firebase/firebase_conf";
import { signOut, signInAnonymously } from "firebase/auth";

function Landing(props) {
  const navigate = useNavigate();

  // auth 초기화 후 네비게이션 처리하는 함수
  const handleAuthResetAndNavigate = () => {
    signOut(auth)
      .then(() => {
        return signInAnonymously(auth);
      })
      .then(() => {
        navigate("/find/0");
      })
      .catch((error) => {
        console.error("Error during auth reset or navigation:", error);
      });
  };

  return (
    <LayoutWithFullContentHeight>
      <Navbar onClick={handleAuthResetAndNavigate} /> {/* 함수 참조가 아닌 바로 호출 */}
      <WithImageBackground onClick={handleAuthResetAndNavigate} /> {/* 함수 참조가 아닌 바로 호출 */}
      <Box
        bgImage={{
          lg: require("../Assets/Image/reviewbackground.png"),
          base: require("../Assets/Image/reviewbackground2.png"),
        }}
        bgSize={{ base: "100% 100%", md: "cover" }}
        bgRepeat={"no-repeat"}
        bgPosition={"center"}
        bgColor={"rgba(245,245,245,0.5)"}
      >
        <LogoGridWithTitle />
      </Box>
      <StatsWithDivider onClick={() => navigate("/find/0")} />
      <Box bgColor={"white"}>
        <HeroWithImage onClick={() => navigate("/find/0")} />
      </Box>
      <Box bgColor={"#f3faf8"}>
        <StepwithLine />
      </Box>
      <HeroWithCropedImage onClick={() => navigate("/find/0")} />
      <BeforeAndAfter />
      <BlogWithThreeColumns onClick={() => navigate("/find/0")} />
      <Features onClick={() => navigate("/find/0")} />
      <CTA1 onClick={() => navigate("/find/0")} />
      <Footer onClick={() => navigate("/find/0")} />
    </LayoutWithFullContentHeight>
  );
}

export default Landing;