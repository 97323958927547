import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Image,
  Img,
  Stack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { MdArrowForward } from "react-icons/md";

export const WithImageBackground = (props: { onClick: () => void }) => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box bg="gray.800" as="section" position="relative">
      <Box position="relative" zIndex={1} color="white">
        <Box
          maxW={{ base: "xl", md: "7xl" }}
          mx="auto"
          px={{ base: "4", md: "8" }}
          h={{ base: "100vh", md: "100vh" }}
        >
          <Center h="100vh" mt={{ base: "-40%", md: 0 }}>
            <Stack
              w="full"
              maxW={{ base: "xl", md: "7xl" }}
              spacing={6}
              alignItems="flex-start"
            >
              <Heading
                width="347px"
                fontFamily="'Cafe24 Ssurround', sans-serif"
                fontWeight="700"
                fontSize={{ base: "lg", md: "3xl" }}
                lineHeight={{ base: "20px", lg: "40px" }}
                letterSpacing="-0.01em"
                color="#0D525C"
                textAlign="left"
                mb={4}
              >
                나에게 꼭 필요한 것만,
                <br />
                나에게 꼭 필요한 만큼
              </Heading>

              <Heading
                as="h1"
                width={{ base: "293px", md: "962px" }}
                fontFamily="'Cafe24 Ssurround', sans-serif"
                fontWeight="700"
                fontSize={{ base: "xl", md: "5xl" }}
                lineHeight={{ base: "20px", lg: "70px" }}
                letterSpacing="-0.01em"
                color="#0D525C"
                textAlign="left"
                mt={-2}
              >
                {isMobile ? (
                  <>
                    차원이 다른
                    <br /> 1:1 개인 맞춤 영양제의 시작
                    <br />
                    지금 바로 더 건강해지세요
                  </>
                ) : (
                  <>
                    차원이 다른 1:1 개인 맞춤 영양제의 시작
                    <br />
                    지금 바로 더 건강해지세요
                  </>
                )}
              </Heading>

              <Button
                px="8"
                rounded="full"
                size={{ base: "sm", md: "lg" }}
                fontWeight="bold"
                onClick={props.onClick}
                rightIcon={<MdArrowForward />}
                bgGradient="linear-gradient(94.15deg, #015A68 0%, #319694 100%)"
                width={{ base: "100%", md: "auto" }}
                mt={6}
              >
                나에게 꼭 맞는 영양제 찾기
              </Button>
            </Stack>
          </Center>
        </Box>
      </Box>
      {/* Background Image */}
      <Flex
        id="image-wrapper"
        position="absolute"
        insetX="0"
        insetY="0"
        w="full"
        h="full"
        overflow="hidden"
        align="center"
      >
        <Box position="relative" w="full" h="full" overflow={"hidden"}>
          <Img
            src={useBreakpointValue({
              base: require("../../Assets/main2.png"), // 모바일에서 사용되는 이미지
              lg: require("../../Assets/main.png"), // 데스크탑에서 사용되는 이미지
            })}
            alt="Main Image"
            w="full"
            h="full"
            objectFit="cover"
            objectPosition="center center"
            position="absolute"
            left="0"
            top="0"
            aspectRatio={16 / 9}
          />
          <Box
            bgGradient={bgGradient}
            opacity={0.7}
            position="absolute"
            w="full"
            h="full"
          />
        </Box>
        <Image
          position="absolute"
          src={require("../../Assets/Image/illust.png")}
          alt="Main Image"
          w="634.14px"
          h="auto"
          objectFit="contain"
          objectPosition="top bottom"
          bottom="0"
          right="0"
        />
      </Flex>
    </Box>
  );
};

export const bgGradient = "linear-gradient(45deg, #E1FAFF 0%, #E7FFDE 100%)";