import { Flex, StackProps, useMediaQuery } from '@chakra-ui/react'
import { StepCircle } from './StepCircle'

interface StepProps extends StackProps {
  isCompleted: boolean
  isActive: boolean
  isLastStep: boolean
  label: string
  imageSrc: string
}

export const Step = (props: StepProps) => {
  const { isActive, isCompleted, isLastStep, label, imageSrc, ...stackProps } = props

  const [isMobile] = useMediaQuery("(max-width: 990px)")

  return (
    <Flex
      flex={isLastStep ? '0' : '1'}
      justify="center"
      align="center"
      position="relative"
      {...stackProps}
    >
      <StepCircle isActive={isActive} isCompleted={isCompleted} label={label} imageSrc={imageSrc} />
    </Flex>
  )
}
